/* Remove default margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure the body takes up the full screen */
html, body {
  width: 100%;
  height: 100%;
  background-color: #020231; /* Match your background */
  color: white;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  padding-top: 70px;
}
