.about-container {
  text-align: center;
  background: #020231;
  color: white;
  padding: 50px 20px;
  height: 100vh;
}

.about-header h1 {
  font-size: 3rem;
  font-weight: bold;
}

.about-header .highlight {
  color: #7b7ff7;
}

.about-header p {
  font-size: 1.2rem;
  margin-top: 15px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.8;
}

.about-header .link {
  color: #7b7ff7;
  font-weight: bold;
}

.steps-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.step-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: left;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.step-card:hover {
  transform: translateY(-5px);
}

.step-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-number {
  font-size: 2rem;
  font-weight: bold;
}

.step-icon {
  font-size: 1.5rem;
  color: #7b7ff7;
}

h2 {
  font-size: 1.5rem;
  margin-top: 10px;
}

p {
  font-size: 1rem;
  opacity: 0.8;
}

.separator {
  border: none;
  border-top: 2px solid  #243b55;
  margin-left: 30px;
  margin-bottom: 20px;
  margin-right: 30px;
}
