.faq-container {
  background-color: #020231;
  color: white;
  padding: 40px;
  text-align: center;
  height: 100vh;
}

.faq-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.faq-title span {
  color: #6366f1; /* Highlight color for the 'Q' */
}

.faq-subtitle {
  font-size: 1rem;
  margin-bottom: 20px;
  opacity: 0.7;
}

.faq-list {
  max-width: 600px;
  margin: 0 auto;
}

.faq-item {
  border-left: 2px solid white;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-question {
  font-size: 1.2rem;
  font-weight: bold;
}

.faq-answer {
  margin-top: 5px;
  font-size: 0.9rem;
  opacity: 0.8;
}

.separator {
  border: none;
  border-top: 2px solid #243b55;
  margin-left: 30px;
  margin-bottom: 20px;
  margin-right: 30px;
}