.transcription-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
  height: 100vh;
  padding: 40px 20px;
  background-color: #020231;
  color: white;
}

/* Left Section - Transcription Info */
.transcript {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-right: 30px;
}

.transcription-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
}

.transcription-description {
  font-size: 1.2rem;
  margin: 20px 0;
}

.upload-button {
  align-self: flex-start;
  background-color: #222;
  color: white;
  border: solid 1px blue;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.upload-button:hover {
  background-color: #0056b3;
}

/* Right Section - Upload Area */
.upload-section {
  flex: 1;
  background-color: #020231;
  padding: 30px;
  color: white;
}

.upload-title {
  font-size: 1.5rem;
}

.file-input {
  display: block;
  margin: 15px 0;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  width: 100%;
}

.or-text {
  font-size: 1rem;
  color: #ccc;
  text-align: left;
  margin: 10px 0;
}

.text-input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.transcribe-button {
  margin-top: 20px;
  background-color: #28a745;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.transcribe-button:hover {
  background-color: #218838;
}

.transcription-result {
  margin-top: 20px;
  font-size: 1rem;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
}

.support-text {
  margin-top: 20px;
  font-size: 1rem;
  color: #ccc;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .transcription-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .transcript {
    padding-right: 0;
    text-align: center;
  }

  .upload-section {
    border-left: none;
    margin-top: 20px;
  }

  .text-input, .file-input, .transcribe-button {
    width: 100%;
  }
}

.audio-player {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.audio-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.audio-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.audio-button:hover {
  background-color: #0056b3;
}

.input-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
}


.audio-icon {
  position: absolute;
  left: 10px;
  width: 24px;
  height: 24px;
  opacity: 0.6;
}

.text-input {
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: none;
  outline: none;
  font-size: 16px;
  background: transparent;
  cursor: pointer;
}

.separator {
  border: none;
  border-top: 2px solid #243b55; 
  margin-left: 30px;
  margin-bottom: 20px;
  margin-right: 30px;
}

.text-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjusts spacing between the icon and button */
}


.record-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;
  background-color: blue; /* Default color before recording */
}

.record-button.recording {
  background-color: red; /* Turns red while recording */
}
