.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  background-color: #020231;
  color: white;
}

.navbar-title {
  font-size: 1.8rem;
  font-weight: bold;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.nav-link {
  margin: 0 15px;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #007bff;
}

.new-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.new-button:hover {
  background-color: #0056b3;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #020231;
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  .navbar-links.active {
    display: flex;
  }

  .hamburger {
    display: block;
  }

  .nav-link {
    margin: 10px 0;
    font-size: 1.2rem;
  }
}
