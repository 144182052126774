.footer {
  background-color: #222;
  color: white;
  text-align: center;
  padding: 15px 0;
  font-size: 1rem;
}

.footer p {
  margin: 0;
}
