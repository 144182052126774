.result-container {
  max-width: 100%;
  margin-left: 30px;
  margin-right: 30px;
  height: 100vh;
  padding: 2rem;
  background: #131a2a;
  color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.1);
  text-align: center;
}

.result-title {
  font-size: 2rem;
  font-weight: bold;
}

.highlight {
  color: #7162ff;
}

.result-file {
  font-size: 1rem;
  color: #bbb;
  margin-top: 10px;
}

.audio-player {
  width: 100%;
  margin-top: 15px;
  background: #222;
  border-radius: 8px;
}

.result-section {
  margin-top: 1.5rem;
  padding-top: 0.75rem;
  border-top: 1px solid #555;
}

.result-heading {
  font-size: 1.2rem;
  font-weight: medium;
  color: #ccc;
}

.result-text {
  color: #ddd;
  margin-top: 0.5rem;
  line-height: 1.6;
}

.language-select {
  margin-top: 10px;
  padding: 8px;
  border-radius: 5px;
  background: #222;
  color: white;
  border: 1px solid #555;
}

.translate-btn {
  margin-top: 10px;
  padding: 8px 15px;
  background: #7162ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.translate-btn:hover {
  background: #5a4ccf;
}

.translated-text {
  margin-top: 10px;
  font-style: italic;
  color: #90caf9;
}

.error-message {
  color: #ff5555;
  font-size: 1.2rem;
  margin-top: 20px;
}

.retry-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background: #ff5555;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.retry-btn:hover {
  background: #cc4444;
}
