
.transcribing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #1a1a2e;
  color: white;
}

.transcribing-title {
  font-size: 2rem;
  font-weight: bold;
}

.transcribing-subtitle {
  font-size: 1rem;
  color: #aaa;
}

.transcribing-bars {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
}

.transcribing-bar {
  width: 40px;
  height: 5px;
  background: white;
  border-radius: 4px;
}
